<template>
  <div class="404">
    404
  </div>
</template>

<script>
export default {
  name: 'Page404'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
